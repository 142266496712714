<template>
    <div class="order">
        <!-- tab切换 -->
        <div class="order-nav">
            <div :class="['order-nav-link',{'ac':status == 0}]" @click="status = 0">全部</div>
            <div :class="['order-nav-link',{'ac':status == 1}]" @click="status = 1">待付款</div>
            <div :class="['order-nav-link',{'ac':status == 2}]" @click="status = 2">待发货</div>
            <div :class="['order-nav-link',{'ac':status == 3}]" @click="status = 3">待收货</div>
            <div :class="['order-nav-link',{'ac':status == 4}]" @click="status = 4">已完成</div>
        </div>
        <!-- 订单列表 -->
        <order-list :orderList="orderList" :isLoad="isLoad" :loadLastText="loadLastText" @getMore="getGoodsOrderList"
            @changeList="resetList" ref="orderListRef" from="order"></order-list>
    </div>
</template>

<script>
    import OrderList from "./OrderChildren/OrderList";
    import {
        getGoodsOrderList
    } from "@/http/api";

    export default {
        components: {
            OrderList
        },
        data() {
            return {
                userToken: "",
                status: 0,
                orderList: [],
                pages: 1,
                loadLastText: false,
                isLoad: false
            };
        },
        mounted() {
            this.userToken = this.$LStorage.getItem("userToken").user_token;
            this.getGoodsOrderList();
        },
        methods: {
            //获取实物订单列表
            async getGoodsOrderList() {
                const res = await getGoodsOrderList({
                    data: {
                        user_token: this.userToken,
                        order_status: this.status,
                        pages: this.pages
                    }
                });
                this.isLoad = true;
                this.$refs.orderListRef.changeLoading();
                if (res.code == "200") {
                    this.orderList = this.orderList.concat(res.data);
                    if (res.data.length < res.numPage) {
                        this.loadLastText = true;
                    } else {
                        this.pages++;
                        this.loadLastText = false;
                    }
                }
            },
            // 重置列表数据
            resetList() {
                this.orderList = [];
                this.pages = 1;
                this.loadLastText = false;
                this.isLoad = false;
                this.getGoodsOrderList();
            }
        },
        watch: {
            status() {
                this.resetList();
            }
        },
    };
</script>

<style lang="scss" scoped>
    .order {
        box-sizing: border-box;
        min-height: 100vh;
        background: #f6f6f6;
        padding-top: 1.2rem;

        * {
            box-sizing: border-box;
        }

        .order-nav {
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            display: flex;
            align-items: center;
            background: #fff;

            .order-nav-link {
                height: 1.2rem;
                width: 25%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 0.28rem;
                color: #afafaf;
                border-bottom: 1px solid #fff;
            }

            .order-nav-link.ac {
                color: #e50012;
                border-bottom: 1px solid #e50012;
                font-weight: bold;
            }
        }

        .order-list {
            width: 100vw;
            overflow-y: hidden;
            padding: 4vw 3vw;

            .null-box {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 1rem 0;

                img {
                    width: 60vw;
                    margin-bottom: 0.5rem;
                }

                p {
                    font-size: 0.26rem;
                    color: #afafaf;
                }
            }

            .order-grid {
                background: #fff;
                border-radius: 5px;
                overflow: hidden;
                padding: 0 1vw;

                .order-hd {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    height: 0.84rem;
                    font-size: 0.28rem;
                    border-bottom: 1px solid #eee;

                    .order-status {
                        color: #da1313;
                    }
                }

                .goods-list {
                    border-bottom: 1px solid #eee;
                }

                .goods-grid {
                    height: 28vw;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    img {
                        width: 24vw;
                        height: 24vw;
                    }

                    .goods-info {
                        display: flex;
                        justify-content: space-between;
                        flex-direction: column;
                        height: 24vw;
                        width: 64vw;

                        .goods-name {
                            font-size: 0.26rem;
                            color: #323233;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }

                        .goods-spec {
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            background: #f5f5f5;
                            border-radius: 5px;
                            box-sizing: border-box;
                            font-size: 0.24rem;
                            color: #848484;
                        }

                        .row {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            .goods-price {
                                font-size: 0.3rem;
                                font-weight: bold;
                                color: #da1313;
                            }

                            .goods-num {
                                font-size: 0.24rem;
                            }
                        }
                    }
                }

                .order-bot {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    height: 1.13rem;

                    .btn {
                        width: 1.4rem;
                        height: 0.6rem;
                        border-radius: 5px;
                        border: 1px solid #c0c0c0;
                        color: #585858;
                        font-size: 0.26rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-left: 0.35rem;
                    }

                    .btn.red {
                        color: #da1313;
                        border: 1px solid #da1313;
                    }
                }
            }
        }
    }
</style>
